import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FavouritesService {

  favourites$: Observable<any>;
  private favouriteSubject: BehaviorSubject<any>;

  private favourites = {
    salons: [],
    services: [],
  };

  constructor() { 
    const favourites = localStorage.getItem('favourites');

    this.favourites = favourites ? JSON.parse(favourites) : this.favourites;
    this.favouriteSubject = new BehaviorSubject<any>(this.favourites);
    this.favourites$ = this.favouriteSubject.asObservable();
  }
 
  addFavourite(favourite, type: 'salons'|'services') {
    this.favourites[type].push(favourite);

    // TODO - set this in DB (maybe?)

    localStorage.setItem('favourites', JSON.stringify(this.favourites));
    this.favouriteSubject.next(this.favourites);
  }

  removeFavourite(favourite_index, type: 'salons'|'services') {
    this.favourites[type].splice(favourite_index, 1);

    // TODO - set this in DB (maybe?)

    localStorage.setItem('favourites', JSON.stringify(this.favourites));
    this.favouriteSubject.next(this.favourites);
  }

  getFavourites(type: 'salons'|'services'|null = null) {
    if (type) {
      return this.favourites[type];
    }

    return this.favourites;
  }

  isInFavourites(id, type: 'salons'|'services') {
    let isInFavourites = false;

    if (this.favourites[type].length > 0) {
      this.favourites[type].forEach(favourite => {
        if (favourite.id === id) {
          isInFavourites = true;
          return false;
        }
      });
    }

    return isInFavourites;
  }
}
