import { Salon } from './../../interfaces/salon';
import { EnvironmentService } from './../environment/environment.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotyfService } from './../notyf/notyf.service';
import { ApiService } from './../api/api.service';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private _settings: {};
  private _salonSettings: {};
  private _timer;
  private onHomePage = true;

  settings$: Observable<any>;
  private settingsSubject: BehaviorSubject<any>;

  constructor(
    private api: ApiService,
    private notyf: NotyfService, 
    private platform: Platform,
    private environment: EnvironmentService,
    private router: Router,
  ) { 
    this._settings = JSON.parse(localStorage.getItem('settings')) || this.environment.settings;

    this.settingsSubject = new BehaviorSubject<any>(this._settings);
    this.settings$ = this.settingsSubject.asObservable();

    this.updateSettings();
  }

  private checkIfOnSalonPage() {
    return (this.router.url.indexOf('/salon/') > -1);
  }

  private checkIfOnHomePage() {
    return (this.router.url === '/');
  }

  private checkIfOnCheckoutPage() {
    return (this.router.url === '/checkout' || this.router.url === 'checkout');
  }

  async getPlatform(): Promise <string>{

    if(this.platform.is('mobileweb') || this.platform.is('desktop')){

      return 'web';

    }else if(this.platform.is('ios')){

      return 'ios';

    }else{

      return 'android';

    }

  }
  
  async updateSettings() {
    let data = {};

    if (this.checkIfOnSalonPage() || this.checkIfOnCheckoutPage()) {
      const salon: Salon = JSON.parse(localStorage.getItem('current-salon'));
      data['salon_id'] = salon.id;
    }

    const response = await this.api.post('/settings/salon', data).toPromise();

    if (response['success']) {
      if (this.checkIfOnSalonPage() || this.checkIfOnCheckoutPage()) {
        this._salonSettings = response['data'];

        // explicitly trigger the subject so observers can be updated when these are set
        this.settingsSubject.next(this._settings);

        this.setTimer();

        return this._salonSettings;
      } else {
        this._settings = response['data'];
        this.settingsSubject.next(this._settings);

        localStorage.setItem('settings', JSON.stringify(this._settings));
      }
    }

    this.setTimer();

    return this._settings;
  }

  setTimer() {
    if (this._timer) {
      clearTimeout(this._timer);
    }

    // check for settings updates once a day
    this._timer = setTimeout(() => {
      this.updateSettings();
    }, 24 * 60 * 60 * 100);
  }

  getSettings(group: string = null) {
    if (group) {
      if (this.checkIfOnCheckoutPage() && typeof this._salonSettings !== 'undefined' && typeof this._salonSettings[group] !== 'undefined') {
        return this._salonSettings[group];
      }

      return this._settings[group] || this._settings;
    }

    return this._settings;
  }

  getSetting(group: string, name: string) {
    if ((this.checkIfOnCheckoutPage() || this.checkIfOnSalonPage()) && typeof this._salonSettings !== 'undefined' && typeof this._salonSettings[group][name] !== 'undefined') {
      return this._salonSettings[group][name];
    }

    return typeof this._settings[group] !== 'undefined' && typeof this._settings[group][name] !== 'undefined' ? this._settings[group][name] : undefined;
  }
}
