import { NotyfService } from "./../notyf/notyf.service";
import { Subscription } from "rxjs";
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { ApiService } from "./../api/api.service";
import { AuthService } from "./../auth/auth.service";
import { Platform, AlertController } from "@ionic/angular";
import { Injectable } from "@angular/core";
import { INotificationPayload } from "cordova-plugin-fcm-with-dependecy-updated";
// import { FirebaseService } from '../firebase/firebase.service';

@Injectable({
  providedIn: "root",
})
export class FcmService {
  hasPermission: boolean;
  token: string = null;
  pushPayload: INotificationPayload;

  initialPayload: INotificationPayload;

  fcmIsSetup: boolean = false;

  private tokenRefreshSub: Subscription = null;
  private onNotificationSub: Subscription = null;
  private saveDeviceTokenSub: Subscription = null;
  private webTokenSub: Subscription = null;
  private loggedInSub: Subscription = null;

  private isLoggedIn: boolean;

  constructor(
    private platform: Platform,
    private auth: AuthService,
    private api: ApiService,
    private fcm: FCM,
    private notyf: NotyfService,
    // private firebase: FirebaseService,
    private alertController: AlertController
  ) {
    this.loggedInSub = this.auth.loggedIn$.subscribe((isLoggedIn: boolean) => {
      this.isLoggedIn = isLoggedIn;

      if (this.fcmIsSetup === false) {
        if (isLoggedIn === true) {
          this.setupFCM();
        }
      } else {
        if (isLoggedIn === false) {
          this.removeToken();
        }
      }
    });
  }

  async getToken() {
    this.token = await this.fcm.getToken();

    return this.token;
  }

  async getPermission() {
    this.hasPermission = await this.fcm.requestPushPermission();
  }

  checkHasPermission() {
    return this.hasPermission;
  }

  async getPushPayload() {
    this.pushPayload = await this.fcm.getInitialPushPayload();
  }

  async setupFCM() {
    // const isLoggedIn = await this.auth.isLoggedIn(true, false);

    if (this.isLoggedIn === false || this.fcmIsSetup === true) {
      return;
    }

    if (this.platform.is("cordova")) {
      const wasPermissionGiven: boolean =
        await this.fcm.requestPushPermission();

      if (wasPermissionGiven) {
        this.setupMobile();
      }
    }
  }

  private setupMobile() {
    this.fcm.hasPermission().then(async (hasPermission: boolean) => {
      if (hasPermission) {
        if (this.tokenRefreshSub === null) {
          this.tokenRefreshSub = this.fcm
            .onTokenRefresh()
            .subscribe((newToken) => {
              this.token = newToken;
              this.saveToken();
            });
        }

        if (this.onNotificationSub === null) {
          this.onNotificationSub = this.fcm
            .onNotification()
            .subscribe(async (payload) => {
              // this function is ran when a push notification is received when the user is using the app
              // when the app is minimised/in the background or closed, a system notification is delivered as usual
              this.pushPayload = payload;

              const alert = await this.alertController.create({
                header: payload.title,
                message: payload.body,
                buttons: ["OK"],
              });

              await alert.present();
            });
        }

        await this.getPermission();
        await this.getToken();
        await this.getPushPayload();
        await this.saveToken();
      }
    });
  }

  // private setupWeb() {
  //   if (this.webTokenSub === null) {
  //     this.webTokenSub = this.firebase.requestPermission().subscribe(
  //       token => {
  //         console.log('permission for notifications granted!');
  //         this.saveToken(token);
  //         this.firebase.receiveMessage();
  //       },
  //       error => {
  //         console.log('error requesting permission.');
  //         console.log({error});
  //         this.webTokenSub.unsubscribe();
  //         this.webTokenSub = null;
  //       }
  //     );
  //   }
  // }

  get pushPayloadString() {
    return JSON.stringify(this.pushPayload, null, 4);
  }

  async saveToken(token = null) {
    if (token === null) {
      token = this.token;
    }

    if (token !== null) {
      if (this.saveDeviceTokenSub !== null) {
        this.saveDeviceTokenSub.unsubscribe();
      }

      try {
        const response = await this.api
          .post("/user/save-device-token", { token: token })
          .toPromise();

        if (this.token !== null && response["success"] === true) {
          this.fcmIsSetup = true;
        }
      } catch (error) {
        // 401 is unauthorised - the user isnt logged in
        if (error.status !== 401) {
          console.log({ error });
        } else {
          this.auth.logout();
        }
      }
    }
  }

  async removeToken() {
    const response = await this.api
      .post("/user/remove-device-token", { token: this.token })
      .toPromise();
    this.fcmIsSetup = false;
  }
}
