import { NotyfService } from "src/app/services/notyf/notyf.service";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { Category } from "src/app/interfaces/category";
import { CategoriesService } from "src/app/services/categories/categories.service";
import { SettingsService } from "./../../services/settings/settings.service";
import { CartService } from "./../../services/cart/cart.service";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AlertController, NavController, Platform } from "@ionic/angular";
import { filter } from "rxjs/operators";
import { AuthService } from "src/app/services/auth/auth.service";
import * as moment from "moment";
import { EnvironmentService } from "src/app/services/environment/environment.service";
import { threadId } from "worker_threads";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  offcanvasOpen = false;
  menuShown = false;
  loggedIn = false;
  currentPlatform: string = "";
  currentRoute: string = "";

  cart = [];
  cart_empty = true;
  firstRoute = true;

  @Input() mobileHeader = true;

  copyright_year;
  app_logo;
  has_about_us_page = false;
  has_gallery_page = false;
  show_services_dropdown = false;
  allow_close_services_dropdown = false;
  salon_app = false;
  categories: Category[] = [];
  positionAbsolute = false;
  mobile_app = false;

  @ViewChild("mainNav") mainNav: ElementRef;
  @ViewChild("offcanvasFooter") offcanvasFooter: ElementRef;

  app_version: string;

  get cartCount() {
    return this.cart.length;
  }

  constructor(
    private alertController: AlertController,
    public auth: AuthService,
    private navController: NavController,
    private router: Router,
    private cartService: CartService,
    private settingsService: SettingsService,
    private env: EnvironmentService,
    private categoriesService: CategoriesService,
    private platform: Platform,
    private appVersion: AppVersion,
    private notyf: NotyfService
  ) {}

  async ngOnInit() {
    this.auth.loggedIn$.subscribe((isLoggedIn: boolean) => {
      this.loggedIn = isLoggedIn;
    });

    this.currentPlatform = await this.settingsService.getPlatform();

    this.router.events
      // Here we are passing all events through the pipe. The filter will only return NavigationEnd events
      .pipe(filter((e) => e instanceof NavigationEnd))
      // Get the current route from the event
      .subscribe((event: NavigationEnd) => {
        if (this.currentRoute !== undefined) {
          if (this.firstRoute && document.referrer.indexOf(window.location.hostname) === -1) {
            this.firstRoute = false;
          }
        }

        this.currentRoute = event.url;
        this.closeOffcanvasNav();
      });

    // listening for changes to the cart
    this.cartService.cart$.subscribe((cart) => {
      this.cart = cart;

      if (this.cart.length > 0) {
        this.cart_empty = false;
      } else {
        this.cart_empty = true;
      }
    });

    this.copyright_year = moment().format("YYYY");
    this.app_logo = this.settingsService.getSetting("salon", "salon_logo");

    this.settingsService.settings$.subscribe((response) => {
      console.log({ navSettings: response });

      this.app_logo = response.salon.salon_logo;
      this.has_about_us_page = response.app.has_about_us_page === "yes";
      this.has_gallery_page = response.app.has_gallery_page === "yes";

      console.log({
        about_us: {
          response: response.app.has_about_us_page,
          val: this.has_about_us_page,
        },
        gallery: {
          response: response.app.has_gallery_page,
          val: this.has_gallery_page,
        },
      });
    });

    if (this.env.api.salon_id !== null) {
      this.salon_app = true;
    }

    this.categoriesService.categories$.subscribe((categories) => {
      this.categories = categories;
    });

    const platform = await this.settingsService.getPlatform();

    this.mobile_app = platform === "android" || platform === "ios";

    // this.settingsService.updateSettings().then(async () => {
    //   if (this.mobile_app) {
    //     const version_setting_string = `${platform}_app_version`;

    //     this.app_version = await this.settingsService.getSetting('app', version_setting_string);
    //   } else {
    //     this.app_version = '';
    //   }
    // });

    if (this.mobile_app) {
      this.platform.ready().then(async () => {
        try {
          this.app_version = await this.appVersion.getVersionNumber();
        } catch (error) {
          this.notyf.error({
            message: "There was an error getting the version number: " + error,
            duration: 0,
            dismissible: true,
          });
        }
      });
    }
  }

  toggleOffcanvasNav() {
    this.offcanvasOpen = !this.offcanvasOpen;
  }

  closeOffcanvasNav() {
    this.offcanvasOpen = false;

    this.calculatePositionAbsolute();
  }

  openOffcanvasNav() {
    this.offcanvasOpen = true;

    this.calculatePositionAbsolute();
  }

  private calculatePositionAbsolute() {
    const totalHeight = this.mainNav.nativeElement.scrollHeight;

    if (totalHeight <= this.platform.height()) {
      this.positionAbsolute = true;
    } else {
      this.positionAbsolute = false;
    }
  }

  async logout() {
    this.auth.logout();

    const alert = await this.alertController.create({
      header: "Success",
      message: "You have successfully been logged out!",
      buttons: [
        {
          text: "OK",
          handler: () => {
            this.navController.navigateRoot("/");
          },
        },
      ],
    });

    await alert.present();
  }

  goBack() {
    this.navController.back();
  }

  closeServicesDropdown(category: Category = null) {
    if (this.allow_close_services_dropdown === true) {
      this.show_services_dropdown = false;
      this.allow_close_services_dropdown = false;
    }

    if (category !== null) {
      this.categoriesService.setActiveCategory(category);
    }

    this.calculatePositionAbsolute();
  }

  openServicesDropdown() {
    this.show_services_dropdown = true;

    setTimeout(() => {
      this.calculatePositionAbsolute();
    }, 0);
    // this.calculatePositionAbsolute();
  }

  toggleServicesDropdown() {
    this.show_services_dropdown = !this.show_services_dropdown;

    if (this.show_services_dropdown === true) {
      setTimeout(() => {
        this.allow_close_services_dropdown = true;
      }, 100);
    }

    setTimeout(() => {
      this.calculatePositionAbsolute();
    }, 0);
    // this.calculatePositionAbsolute();
  }

  openSearch() {
    //
  }
}
