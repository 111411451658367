// import { NgxNotyfService } from 'ngx-notyf';
import { Injectable } from '@angular/core';
import { INotyfNotificationOptions, INotyfOptions, Notyf, NotyfNotification } from 'notyf';

@Injectable({
  providedIn: 'root'
})
export class NotyfService {
  private notyf: Notyf;
  private options: Partial<INotyfOptions> = {
    position: {
      x: "right",
      y: "top",
    },
    dismissible: true,
    duration: 5 * 1000, // 5 seconds
    types: [
      {
        type: 'info',
        className: 'bg-info',
        icon: {
          className: 'fas fa-info-circle'
        },
      }
    ]
  };

  constructor() { 
    this.notyf = new Notyf(this.options);// this._notyf.setOptions(this.options);
  }

  newInstance(options: Partial<INotyfOptions>) {
    return new Notyf(options);
  }

  error(message: string|Partial<INotyfNotificationOptions>) {
    return this.notyf.error(message);
  }

  success(message: string|Partial<INotyfNotificationOptions>) {
    return this.notyf.success(message);
  }

  dismiss(notification: NotyfNotification) {
    return this.notyf.dismiss(notification);
  }

  open(options: Partial<INotyfNotificationOptions>) {
    return this.notyf.open(options);
  }
}
