import { Directive, ElementRef, Output, EventEmitter, HostListener } from "@angular/core";

@Directive({
  selector: "[appClickOutside]",
})
export class ClickOutsideDirective {
  constructor(private _elementRef: ElementRef) {}

  public clickOutside = new EventEmitter();

  @HostListener("document:click", ["$event.target"])
  public onClick(target_element: HTMLElement): void {
    if (!target_element) {
      return;
    }
    // if (target_element.classList.contains('dropdown-toggle')) {
    //   if (target_element)
    // }
    const clicked_inside = this._elementRef.nativeElement.contains(target_element);
    if (!clicked_inside) {
      this.clickOutside.emit(null);
    }
  }
}
