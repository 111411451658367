import { Category } from 'src/app/interfaces/category';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { Salon } from 'src/app/interfaces/salon';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss'],
})
export class ServicesListComponent implements OnInit {

  @Input() categoryName: string;
  @Input() salon: Salon;
  @Input() services: [];
  @Input() category: Category;

  on_bonnie: boolean;

  constructor(
    private env: EnvironmentService,
  ) { }

  ngOnInit() {
    // console.log({services: this.services});
    this.on_bonnie = this.env.api.salon_id === null;
  }

  setActiveSalon(salon: Salon, event: MouseEvent) {
    const currentSalon = JSON.parse(localStorage.getItem('current-salon'));

    if (currentSalon === null) {
      localStorage.setItem('current-salon', JSON.stringify(salon));
    }

    localStorage.setItem('next-salon', JSON.stringify(salon));
  }
}
