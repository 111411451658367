import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  // 

  /**
   * the first dateFormat parameter will be used to format the supplied date if no second format is passed,
   * otherwise the first format will be used to parse the date and the second one will be used to format it
   * 
   * @param value - passed via pipe. Can be a Date object or Moment object. Can also be a string if you supply a format to parse the string
   * @param dateFormat - format of returned date, or the format used to parse the supplied date if secondDateFormat is ommited
   * @param secondDateFormat - format of the returned date. null by default.
   */
  transform(value: Date | moment.Moment | string, dateFormat: string, secondDateFormat: string = null): any {
    let m = secondDateFormat !== null ? moment(value, dateFormat) : moment(value);

    return m.format(secondDateFormat !== null ? secondDateFormat : dateFormat);
  }
}
