import { CategoriesService } from 'src/app/services/categories/categories.service';
import { Component, Input, OnInit } from '@angular/core';
import { Category } from 'src/app/interfaces/category';

@Component({
  selector: 'app-mobile-page-header',
  templateUrl: './mobile-page-header.component.html',
  styleUrls: ['./mobile-page-header.component.scss'],
})
export class MobilePageHeaderComponent implements OnInit {

  @Input() active_category: Category = null;
  @Input() provided_title: string = null;

  title: string = null;

  constructor(
    private categoriesService: CategoriesService,
  ) { }

  ngOnInit() {
    if (this.active_category === null) {
      this.categoriesService.active_category$.subscribe(active_category => {
        this.active_category = active_category;

        if (this.provided_title === null) {
          this.title = this.active_category?.name;
        }
      })
    }
  }
}
