import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private headers: any;
  private base_url: string;

  constructor(
    private http: HttpClient,
    private environment: EnvironmentService,
  ) {
    // let headers = {
    //   'Accept': 'application/json',
    //   'Content-Type': 'application/json'
    // };
    let headers = {};

    this.headers = new HttpHeaders(headers);
    this.base_url = this.environment.api.url;
  }

  get(endpoint, params = {}) {
    return this.http.get(this.getFullUrl(endpoint), {
      headers: this.headers,
      params
    });
  }
  
  post(endpoint, params = {}) {
    return this.http.post(this.getFullUrl(endpoint), params, {
      headers: this.headers
    });
  }

  delete(endpoint, params = {}) {
    return this.http.delete(this.getFullUrl(endpoint), {
      params,
      headers: this.headers,
    });
  }

  private getFullUrl(endpoint) {
    if (endpoint.charAt(0) !== '/') {
      endpoint = '/' + endpoint;
    }

    return this.base_url + endpoint;
  }
}
