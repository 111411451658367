import { CategoriesService } from "src/app/services/categories/categories.service";
import { Category } from "./../../interfaces/category";
import { Component, Input, OnInit, OnChanges } from "@angular/core";
import { Salon } from "src/app/interfaces/salon";
import { FavouritesService } from "src/app/services/favourites/favourites.service";
import { NotyfService } from "src/app/services/notyf/notyf.service";

@Component({
  selector: "app-salon-list",
  templateUrl: "./salon-list.component.html",
  styleUrls: ["./salon-list.component.scss"],
})
export class SalonListComponent implements OnInit, OnChanges {
  @Input() salons: Salon[] = [];
  @Input() active_category: Category = null;
  @Input() no_results: string = "The selected category does not exist on any salon. Please select another one.";
  @Input() title: string = null;
  @Input() show_services: boolean = false;
  @Input() location_string: string = null;

  page_title: string;

  salonClasses = [];

  constructor(
    private favourites: FavouritesService,
    private notyf: NotyfService,
    private categoriesService: CategoriesService
  ) {}

  ngOnInit() {
    this.checkIfSalonsAreInFavourites();

    this.active_category = this.categoriesService.getActiveCategory();

    if (this.title !== null) {
      this.page_title = this.title;
    } else if (this.active_category !== null) {
      this.page_title = this.active_category?.name;
    }

    console.log({ salons: this.salons });
  }

  ngOnChanges() {
    this.active_category = this.categoriesService.getActiveCategory();

    if (this.title !== null) {
      this.page_title = this.title;
    } else if (this.active_category !== null) {
      this.page_title = this.active_category?.name;
    }
  }

  toggleFavourite(favourite, type) {
    if (this.favourites.isInFavourites(favourite.id, type)) {
      this.favourites.removeFavourite(favourite, type);
      this.notyf.success(favourite.name + " was removed from your favourites");
    } else {
      this.favourites.addFavourite(favourite, type);
      this.notyf.success(favourite.name + " was added to your favourites");
    }

    this.checkIfSalonsAreInFavourites();
  }

  checkIfSalonsAreInFavourites() {
    if (this.salons.length > 0) {
      this.salons.forEach((salon) => {
        this.salonClasses.push({
          far: !this.favourites.isInFavourites(salon.id, "salons"),
          fas: this.favourites.isInFavourites(salon.id, "salons"),
          "text-danger": this.favourites.isInFavourites(salon.id, "salons"),
          "fa-heart": true,
        });
      });
    }
  }

  setActiveSalon(salon: Salon, event: MouseEvent) {
    const currentSalon = JSON.parse(localStorage.getItem("current-salon"));

    if (currentSalon === null) {
      localStorage.setItem("current-salon", JSON.stringify(salon));
    }

    localStorage.setItem("next-salon", JSON.stringify(salon));
  }
}
