import { Component, Input } from "@angular/core";

@Component({
  selector: "app-services-list-item",
  templateUrl: "./services-list-item.component.html",
  styleUrls: ["./services-list-item.component.scss"],
})
export class ServicesListItemComponent {
  @Input() item;

  constructor() {}
}
